@use "../vendor" as *;
@use "../settings" as *;

html {
    height: 100%;
    font-family: $font-family-sans-serif;
}

body {
    height: 100%;
    display: flex;
    flex-direction: column;
}

main {
    flex-grow: 1;
}

.btn-default {
    border-color: $nholt-grey;
    color: $nholt-grey;
    background-color: $nholt-background-color;
}

.btn-default:hover {
    border-color: $nholt-grey;
    color: $nholt-grey;
    background-color: $nholt-lightgrey;
}

.sidebar-subheader {
    color: $nholt-background-color!important;
}
