@use "../vendor" as *;

// Start with these colors, while we wait for design input from nholt.
$nholt-background-color: #f9f9f9;
$nholt-basecolor: #8cbfd9;
$nholt-linkcolor: #006699;
$nholt-lightgrey: #cccccc;
$nholt-grey: #666666;



:root {
    --nholt-basecolor: $nholt-basecolor;
    --nholt-background-color: $nholt-background-color;
    --nholt-widget-background-color: #fefefe;
    --nholt-neutral-color-3: #f7f7f7;
    --nholt-neutral-color-5: #f2f2f2;
    --nholt-neutral-color-10: #e6e6e6;
    --nholt-neutral-color-20: #cccccc;
    --nholt-neutral-color-40: #999999;
    --nholt-neutral-color-60: #666666;
    --nholt-neutral-color-80: #333333;
    --nholt-neutral-color-100: #000000;
    --nholt-theme-color-10: #e6f0f5;
    --nholt-theme-color-20: #cce0eb;
    --nholt-theme-color-40: #87b7cf;
    --nholt-theme-color-60: #66a3c2;
    --nholt-theme-color-80: #3384ad;
    --nholt-theme-color-100: #006699;
    --nholt-contrast-color-10: #e6f5f1;
    --nholt-contrast-color-20: #ccebe3;
    --nholt-contrast-color-40: #87cfbe;
    --nholt-contrast-color-60: #66c2ac;
    --nholt-contrast-color-80: #33ad90;
    --nholt-contrast-color-100: #009975;
}

// Text colors from most intense to least intense.
$text-color-0: var(--nholt-neutral-color-100);
$text-color-1: var(--nholt-neutral-color-80);
$text-color-2: var(--nholt-neutral-color-60);
$text-color-3: var(--nholt-neutral-color-40);
$text-color-4: var(--nholt-neutral-color-20);
$text-color-5: var(--nholt-neutral-color-10);

// Neutral colors, grayscale by default -100 is highest contrast (vs. background-color)
$neutral-color-100: var(--nholt-neutral-color-100);
$neutral-color-80: var(--nholt-neutral-color-80);
$neutral-color-60: var(--nholt-neutral-color-60);
$neutral-color-40: var(--nholt-neutral-color-40);
$neutral-color-20: var(--nholt-neutral-color-20);
$neutral-color-10: var(--nholt-neutral-color-10);
$neutral-color-5: var(--nholt-neutral-color-5);
$neutral-color-3: var(--nholt-neutral-color-3);

// for writing on e.g. theme colors, from highest contrast -0 to lowest contrast -2
$text-color-inverse: var(--nholt-neutral-color-3);
$text-color-inverse-1: var(--nholt-neutral-color-10);
$text-color-inverse-2: var(--nholt-neutral-color-20);

$theme-green: $success;
$theme-green-text: $text-color-inverse;

$gutter-width: 15px;
$theme-sm-gutter: 10px;
$theme-xs-gutter: 5px;
$theme-separation-border: 1px dotted $neutral-color-40;

$action-width: 25%;
$status-dot-height: 50px;

$nholt-background-color: #f9f9f9;


// Extra small screen / phone
//** Deprecated `@screen-xs` as of v3.0.1
$screen-xs:                  $grid-breakpoint-xs;
//** Deprecated `@screen-xs-min` as of v3.2.0
$screen-xs-min:              $screen-xs;
//** Deprecated `@screen-phone` as of v3.0.1
$screen-phone:               $screen-xs-min;
$phone:                      $screen-xs-min;

// Small screen / tablet
//** Deprecated `@screen-sm` as of v3.0.1
$screen-sm:                  $grid-breakpoint-sm;
$screen-sm-min:              $screen-sm;
//** Deprecated `@screen-tablet` as of v3.0.1
$screen-tablet:              $screen-sm-min;
$tablet:                     $screen-sm-min;

// Medium screen / desktop
//** Deprecated `@screen-md` as of v3.0.1
$screen-md:                  $grid-breakpoint-md;
$screen-md-min:              $screen-md;
//** Deprecated `@screen-desktop` as of v3.0.1
$screen-desktop:             $screen-md-min;
$desktop:                    $screen-md-min;

// Large screen / wide desktop
//** Deprecated `@screen-lg` as of v3.0.1
$screen-lg:                  $grid-breakpoint-lg;
$screen-lg-min:              $screen-lg;
//** Deprecated `@screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-lg-min;
$lg-desktop:                 $screen-lg-min;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1);
$screen-sm-max:              ($screen-md-min - 1);
$screen-md-max:              ($screen-lg-min - 1);
