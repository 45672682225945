@use "../vendor" as *;
@use "../settings" as *;

  
  footer {
    box-sizing: border-box;
    position: relative;
    background-color: $secondary;
    font-size: $fs-8;

    .footer-content {
      min-height: 4cm;
      display: flex;
      flex-direction: row;
      justify-content: center !important; // required by Edge to center elements.
      gap: 100px;
      align-items: center;
      letter-spacing: .2em;
      color: #fff !important;
      padding: $size-7;

      @include media-breakpoint-down(md) {
        gap: $size-12;
      }

      @include media-breakpoint-down(sm) {
        flex-direction: column-reverse;
        gap: $size-7;
      }

      a, a:hover {
        color: #fff;
      }

      .footer-label {
        color: #b4b4b4
      }

      .norsktest-footer {
        display: flex;
        flex-direction: row;
        align-items: center;

        .contact-info {
          padding: 0 1cm;
          font-variant: small-caps;
        }

        .info-list {
          list-style-type: none
        }
      }

      .footer-menu a {
        display: block;
      }
    }

    .legal {
      padding: $size-3;
      display: flex;
      gap: $size-6;

      @include media-breakpoint-down(xs) {
        text-align: center;
      }
      a, a:hover {
        color: #fff;
      }
    }
  }

