@use "../vendor" as *;
@use "../settings" as *;


nav.navbar {
  background-color: $primary;

  .navbar-brand {

      img {
        max-width: 250px;
      }
  
      @include media-breakpoint-down(md) {
          width: unset!important;
      }
  }

}

.navbar-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  //@media @mobile {
  //  flex-direction: column;
  //  padding: 15px 15px;
  //}

  .navbar-right .actions {
    display: flex;
    flex-direction: row;
    gap: 5px;
    padding: 15px 15px;

    a {
      max-width: 200px;
    }

    //@media @mobile {
    //  flex-direction: column;
    //}
  }

}

.navbar-left {

  .nav {
      flex-grow: 1;

      .nav-item {

          margin: 5px 0;

          .nav-link {
              color: $dark;
          }

          .nav-link:hover {
              // XXX: after moving to components/navbar, make separate $c-navbar-link(-hover,active) variables..?
              background-color: $neutral-color-20;
          }

          .nav-link.active {
              background-color: $neutral-color-3;
          }

      }
  }

}
