@use "variables" as *;

body {
  background-color: $nholt-background-color;
}

.main-container {
  margin-top: 20px;
}

body.profile-page {
    .demographics {
        .action {
            text-align: right;
        }

        td,
        th {
            padding: 2px !important;
        }

        th {
            padding-right: 5px !important;
        }

        th {
            width: 20ex !important;
            text-align: right !important;
        }
    }
    .full-name {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .person > .card {
        margin: 0
    }

    .full-name h1 {
        margin: 0;
    }


    .template-debug {
        display: none;
    }

    [admin] {
        display: none;
    }

    .logout {
        float: right
    }
}

.status-dot {
    display: inline-block;
    border-radius: 50%;
    width: 50px; height: $status-dot-height;
    background-color: $neutral-color-5;
    vertical-align: baseline;
    text-align: center;
    font-size: $status-dot-height * 0.84;

    &.passed {
        background-color: $theme-green;
        color: $theme-green-text;
    }
    //&.failed { background-color: @warning-color; }
    &.sm {
        width: 20px; height: 20px;
        line-height: 20px;
        font-size: 15px;
    }

    ~ .small {
      padding-left: 50px * 1.18;
      font-size: 65%;
    }
}
