@use "../vendor" as *;

$fs-1: 0.3125rem;             // 5px;
$fs-2: 0.375rem;              // 6px;
$fs-3: 0.4375rem;             // 7px;
$fs-4: 0.5rem;                // 8px;
$fs-5: 0.5625rem;             // 9px;
$fs-6: 0.625rem;              // 10px;
$fs-7: 0.6875rem;             // 11px;
$fs-8: 0.75rem;               // 12px;
$fs-9: 0.875rem;              // 14px;
$fs-10: 1.0rem;               // 16px;
$fs-11: 1.125rem;             // 18px;
$fs-12: 1.25rem;              // 20px;
$fs-13: 1.375rem;             // 22px;
$fs-14: 1.5rem;               // 24px;
$fs-15: 1.625rem;             // 26px;
$fs-16: 1.75rem;              // 28px;
$fs-17: 1.875rem;             // 30px;
$fs-18: 2.0rem;               // 32px;
$fs-19: 2.25rem;              // 36px;
$fs-20: 2.5rem;               // 40px;
$fs-21: 2.75rem;              // 44px;
$fs-22: 3.0rem;               // 48px;
$fs-23: 3.25rem;              // 52px;
$fs-24: 3.5rem;               // 56px;
$fs-25: 3.75rem;              // 60px;
$fs-26: 4.0rem;               // 64px;
$fs-27: 5rem;                 // 80px;
