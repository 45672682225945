@use "../vendor" as *;
@use "../settings" as *;

.ResultSet {
    a {
        color: $nholt-linkcolor;
        text-decoration: underline;

        &:hover {
            text-decoration: underline;
        }
    }
    .panel-heading {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }
    .commands {
        margin-left: auto;
    }


    .CheckboxSelectWidget > label {
        display: block;
    }
}

// this removes the clear button on the search input in datasources. should probably be moved
.clear-button {
    display: none;
}

.filterbx {
    min-width: 250px;
}

.filterheader h3 {
    font-size: $fs-12;
}

.filterdef {
    padding: $size-6 0;
}
