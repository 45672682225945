@use "../settings" as *;

#main {
  padding: 3rem;
}

a {
    color: $nholt-linkcolor;
    text-decoration: underline;

    &:hover {
        text-decoration: underline;
    }
}
