@use 'sass:meta';
// we might need bootstrap functions...
@import "bootstrap/scss/functions";

// place in site.html
// @import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");

// define bootstrap overrides

// oklch color picker: https://oklch.com/

$primary: #cce0eb;
//$primary: #d1edfc;
// $primary: rgb(36, 30, 84);                   // design
// $primary: hsl(246.667, 47%, 22%);            // hsl is more intuitive..?
// $primary: oklch(27.71% 0.094 283.38);        // oklch not supported by sass yet

$secondary: #182f4e;
// $secondary: rgb(204, 229, 230);
// $secondary: hsl(182.308, 34%, 85%);
// $secondary: oklch(90.39% 0.026 199.63);

// $danger: #F0D2D2;
// $danger: rgb(240, 210, 210);
// $danger: hsl(0.0, 50%, 88%);
// $danger: oklch(88.88% 0.034 17.8);

// $warning: #FFFADF;
// $warning: rgb(255, 250, 223);
// $warning: hsl(50.625, 100%, 94%);
// $warning: oklch(98.19% 0.036 98.39);

// $info: #DFEAEA;
// $info: rgb(223, 234, 234);
// $info: hsl(180.0, 21%, 90%);
// $info: oklch(92.88% 0.012 196.95);

// $light: #F2F3F4;
// $light: rgb(242, 243, 244);
// $light: hsl(210.0, 8%, 95%);
// $light: oklch(96.37% 0.002 247.84);

// $success: #009b77;
// $success: rgb(0, 126, 132);
// $success: hsl(182.727, 100%, 26%);
// $success: oklch(53.93% 0.092 200.66);

// $dark: #2C2C2C;
// $dark: rgb(44, 44, 44);
// $dark: hsl(0, 0%, 17%);
// $dark: oklch(29.31% 0.0 89.88);


$custom-colors: (
    "primary": $primary,
    "secondary": $secondary,
    // "danger": $danger,
    // "warning": $warning,
    // "info": $info,
    // "light": $light,
    // "success": $success,
    // "dark": $dark
);

:root {
    @each $name, $color in $custom-colors {
        --c-#{$name}: #{$color};
    }
};

// grid
$grid-gutter-width: 0.5rem;

$grid-breakpoint-xs: 480px;
$grid-breakpoint-sm: 768px;
$grid-breakpoint-md: 992px;
$grid-breakpoint-lg: 1200px;
$grid-breakpoint-xl: 1400px;
$grid-breakpoint-xxl: 1800px;

$grid-breakpoints: (
    xs: $grid-breakpoint-xs,
    sm: $grid-breakpoint-sm,
    md: $grid-breakpoint-md,
    lg: $grid-breakpoint-lg,
    xl: $grid-breakpoint-xl,
    xxl: $grid-breakpoint-xxl
);


// typography
// place in site.html
// @import url("https://fonts.googleapis.com/css?family=Titillium+Web:200,300,600");
$font-family-sans-serif:  "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-base: "Helvetica Neue", sans-serif;

// import bootstrap variables (this will override bootstrap !default variables)
@import "bootstrap/scss/variables";

// merge bootstrap variables with custom variables
$theme-colors: map-merge($theme-colors, $custom-colors);

@import "bootstrap/scss/bootstrap";
