@use "../vendor" as *;
@use "../settings" as *;


body.dashboard {

    .center-box {

        flex-grow: 1;
        display: flex;    

        // FIXME: this should probably be in components

        main {
            padding: 2rem;
            overflow-y: scroll;

            .dash-wrapper {
                padding: 2rem;
            }
        }

    }
    .action {
      border: none;
    }
    .wrapper {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
    }

}
