@use "sass:math";
@use "depth" as *;
@use "../vendor" as *;
@use "../settings" as *;

.modal-page {
    height: unset!important;
    width: unset!important;
    // background-color: $nholt-basecolor;
    display: block;

    form, fieldset {
        > div {
            padding: $size-4 0;
        }
    }

    // &.ok {
    //    background-color: $primary;
    // }
    
    // &.danger {
    //    background-color: $danger;
    // }
    
    // &.error {
    //    background-color: $danger;
    // }
    
    // &.warning {
    //    background-color: $warning;
    // }
    
    // &.info {
    //    background-color: $info;
    // }

    .modal-box {
        > h1 {
            margin-bottom: $size-8;
        }
        $transition-duration: .2s;
        transition: margin $transition-duration, padding $transition-duration;

        padding: $size-10;

        margin-block: 2%;
        max-width: 700px;
        min-height: 400px;

        position: relative;
        display: flex;
        flex-direction: column;
        align-content: space-between;

        @extend .depth-2;
        background-color: var(--nholt-widget-background-color);

        @include media-breakpoint-down(md) {
            $margin: $size-5;
            max-width: calc(100% - 2 * #{$margin});
            margin-block: $margin;
            padding: $spacer * 2;
        }

        > header {
            border-bottom: 1px solid var(--nholt-neutral-color-20);
            padding-bottom: var(--g-3);
            margin-bottom: var(--g-3);

            .logo {
                max-width: 400px;

                @include media-breakpoint-down(sm) {
                    max-width: 100%;
                }
            }
        }

        > footer {
            hr {
                width: 30%;
                text-align: left;
            }
        }
    }
}

