@use "../vendor" as *;
@use "../settings" as *;

#sidebar {

    background-color: $primary;
    width: 280px;
    transition: 0.2s ease-in;

    .sidebar-toggle {
        margin-top: -15px !important;
        text-align: right !important;
    }

    .nav-item, .system-status, .sidebar-subheader {
        white-space: nowrap;
        overflow: hidden;
    }
}

#sidebar.collapsed {
    width: 85px;
    transition: 0.2s ease-out;

    .sidebar-toggle {
        text-align: center !important;
    }

    .system-status, .nav-item-title, .sidebar-subheader {
        display: none !important;
    }
}
