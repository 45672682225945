
form {

    .form-check {
        display: grid;
        grid-template-columns: 20px 1fr;
    }

    .errordescr {
        color: var(--bs-red);
    }

    .form-control {
        border-radius: 0 !important;
    }

    label {
        font-weight: 600 !important;
    }

    .form-control:focus {
        box-shadow: none !important;
    }

    .form-control {

        &.validated-false {
            border-color: var(--c-danger);
            //@include depth.depth-1();
            //box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.07), 0 0 8px rgba(227, 92, 92, 0.6);
        }

        &.validated-true {
            border-color: var(--c-success);
            //box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.07), 0 0 8px rgba(112, 224, 175, 0.6);
        }
    }

    input[type="checkbox"] {
        accent-color: var(--c-success);
    }
}
